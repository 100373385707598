// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-fr-tsx": () => import("./../src/pages/about-us.fr.tsx" /* webpackChunkName: "component---src-pages-about-us-fr-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-fr-tsx": () => import("./../src/pages/account.fr.tsx" /* webpackChunkName: "component---src-pages-account-fr-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cause-ansar-fr-mdx": () => import("./../src/pages/cause/ansar.fr.mdx" /* webpackChunkName: "component---src-pages-cause-ansar-fr-mdx" */),
  "component---src-pages-cause-ansar-mdx": () => import("./../src/pages/cause/ansar.mdx" /* webpackChunkName: "component---src-pages-cause-ansar-mdx" */),
  "component---src-pages-cause-atlas-fr-mdx": () => import("./../src/pages/cause/atlas.fr.mdx" /* webpackChunkName: "component---src-pages-cause-atlas-fr-mdx" */),
  "component---src-pages-cause-atlas-mdx": () => import("./../src/pages/cause/atlas.mdx" /* webpackChunkName: "component---src-pages-cause-atlas-mdx" */),
  "component---src-pages-cause-banyummaty-fr-mdx": () => import("./../src/pages/cause/banyummaty.fr.mdx" /* webpackChunkName: "component---src-pages-cause-banyummaty-fr-mdx" */),
  "component---src-pages-cause-banyummaty-mdx": () => import("./../src/pages/cause/banyummaty.mdx" /* webpackChunkName: "component---src-pages-cause-banyummaty-mdx" */),
  "component---src-pages-cause-feedinghope-fr-mdx": () => import("./../src/pages/cause/feedinghope.fr.mdx" /* webpackChunkName: "component---src-pages-cause-feedinghope-fr-mdx" */),
  "component---src-pages-cause-feedinghope-mdx": () => import("./../src/pages/cause/feedinghope.mdx" /* webpackChunkName: "component---src-pages-cause-feedinghope-mdx" */),
  "component---src-pages-cause-water-4-all-fr-mdx": () => import("./../src/pages/cause/water4all.fr.mdx" /* webpackChunkName: "component---src-pages-cause-water-4-all-fr-mdx" */),
  "component---src-pages-cause-water-4-all-mdx": () => import("./../src/pages/cause/water4all.mdx" /* webpackChunkName: "component---src-pages-cause-water-4-all-mdx" */),
  "component---src-pages-cause-zakat-fr-mdx": () => import("./../src/pages/cause/zakat.fr.mdx" /* webpackChunkName: "component---src-pages-cause-zakat-fr-mdx" */),
  "component---src-pages-cause-zakat-mdx": () => import("./../src/pages/cause/zakat.mdx" /* webpackChunkName: "component---src-pages-cause-zakat-mdx" */),
  "component---src-pages-causes-tsx": () => import("./../src/pages/causes.tsx" /* webpackChunkName: "component---src-pages-causes-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-donate-fr-tsx": () => import("./../src/pages/donate.fr.tsx" /* webpackChunkName: "component---src-pages-donate-fr-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-test-tsx": () => import("./../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-donate-fr-tsx": () => import("./../src/templates/donate.fr.tsx" /* webpackChunkName: "component---src-templates-donate-fr-tsx" */),
  "component---src-templates-donate-tsx": () => import("./../src/templates/donate.tsx" /* webpackChunkName: "component---src-templates-donate-tsx" */)
}

