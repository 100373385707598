/** @jsx jsx */
import ShareButton from 'components/ShareButton'
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import {ImageSharpFluid} from '../graphqlTypes'
import DonateNowButton from './DonateNowButton'

type Props = {
  className?: string
  data: {
    heroImage: {
      childImageSharp: {
        fluid: Pick<
          ImageSharpFluid,
          'base64' | 'aspectRatio' | 'src' | 'srcSet' | 'sizes'
        >
      }
    }
  }
  title: string
  text: string
  purpose?: string
  footer?: string
  pagePath: string
}

const CauseHero: React.FC<Props> = ({
  className,
  data,
  title,
  text,
  footer,
  purpose,
  pagePath,
}) => {
  const {heroImage} = data
  return (
    <BackgroundImage
      className={className}
      id="home__hero"
      Tag="div"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
      }}
      sx={{
        py: 37,
        px: [20, , 40],
        mb: [20, , 50],
        width: '100%',
        height: [700, 900, 503],
        display: 'flex',
        '&::before, &::after': {
          backgroundPositionX: 'left',
          backgroundPositionY: ['bottom', , 'center'],
          backgroundSize: ['180%', , 'unset'],
        },
      }}
      fluid={heroImage.childImageSharp.fluid}
      backgroundColor="#F4F7F8"
    >
      <div
        sx={{
          flex: 1,
          maxWidth: ['100%', , '100%', 1440 - 196 * 2],
          mx: 'auto',
          display: 'flex',
          flexDirection: [, 'column', 'row-reverse'],
          alignItems: ['flex-start', 'center'],
        }}
      >
        <div
          id="cause_hero__card"
          sx={{
            position: [, , 'fixed'],
            width: ['100%', 412],
            backgroundColor: 'white',
            px: 28,
            pt: 36,
            pb: 40,
            borderRadius: 16,
            boxShadow: '0px 12px 26px #F2F2F2',
          }}
        >
          <h1
            sx={{
              fontFamily: 'Lato Medium',
              fontSize: 22,
              my: 0,
            }}
          >
            {title}
          </h1>
          <p
            sx={{
              mt: 10,
              fontSize: [20, , 24],
              fontWeight: 300,
              mb: 0,
              lineHeight: '29px',
              color: '#042D37',
            }}
          >
            {text}
          </p>
          {footer && (
            <div
              sx={{
                mt: 8,
                fontSize: 12,
                color: 'soft',
              }}
            >
              {footer}
            </div>
          )}
          <div
            sx={{
              mt: 32,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <DonateNowButton to={purpose} />
            <ShareButton pagePath={pagePath}></ShareButton>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default CauseHero
