export enum DonationPurpose {
  'General Donation' = 'General Donation',
  'Gaza' = 'Gaza',
  'Ansar' = 'Ansar',
  'Al Wiam quran center' = 'Al Wiam quran center',
  'Noor Ummaty' = 'Noor Ummaty',
  'Udhiya' = 'Udhiya',
  'Zakat Al Fitr' = 'Zakat Al Fitr',
  'Djibouti Center' = 'Djibouti Center',
  'Daawa' = 'Daawa',
  'Zakat' = 'Zakat',
  'Masajid Ummaty' = 'Masajid Ummaty',
  'Water For All' = 'Water For All',
  'Ramadan Basket' = 'Ramadan Basket',
  'Ramadan Iftar' = 'Ramadan Iftar',
  'Feeding Hope' = 'Feeding Hope',
  'Bany Ummaty' = 'Bany Ummaty',
  'Atlas Relief' = 'Atlas Relief',
}

export const DEFAULT_DONATION_PURPOSE = DonationPurpose['General Donation']

export const DonationPurposeSlugs: {[key: string]: DonationPurpose} = {
  sadaqa: DonationPurpose['General Donation'],
  gaza: DonationPurpose['Gaza'],
  wiam: DonationPurpose['Al Wiam quran center'],
  ansar: DonationPurpose['Ansar'],
  daawa: DonationPurpose['Daawa'],
  zakat: DonationPurpose['Zakat'],
  'zakat-al-fitr': DonationPurpose['Zakat Al Fitr'],
  masajid: DonationPurpose['Masajid Ummaty'],
  'djibouti-center': DonationPurpose['Djibouti Center'],
  udhiya: DonationPurpose['Udhiya'],
  water4all: DonationPurpose['Water For All'],
  ramadan: DonationPurpose['Ramadan Iftar'],
  'ramadan-iftar': DonationPurpose['Ramadan Iftar'],
  feedinghope: DonationPurpose['Feeding Hope'],
  banyummaty: DonationPurpose['Bany Ummaty'],
  atlas: DonationPurpose['Atlas Relief'],
  noor: DonationPurpose['Noor Ummaty'],
}

export const DISABLED_DONATION_PURPOSES = [
  DonationPurpose['Djibouti Center'],
  DonationPurpose['Ramadan Basket'],
  DonationPurpose['Ramadan Iftar'],
  DonationPurpose['Zakat Al Fitr'],
  DonationPurpose.Udhiya,
]

export enum DonationType {
  'Monthly' = 'Monthly',
  'One Time' = 'One Time',
}
export enum WaterForAllDonationType {
  'Build Your Own Well' = 'Build Your Own Well',
}
export enum UdhiyaDonationType {
  'Udhiya' = 'Udhiya',
}
export enum RamadanBasketDonationType {
  'Family Basket' = 'Family Basket',
}
export enum RamadanIftarDonationType {
  'Iftar Meal' = 'Iftar Meal',
}
export enum AtlasBasketDonationType {
  'Atlas Basket' = 'Atlas Basket',
}
export enum NoorBasketDonationType {
  'Noor Ummaty' = 'Noor Ummaty',
}

export const AllDonationType = {
  ...DonationType,
  ...WaterForAllDonationType,
  ...UdhiyaDonationType,
  ...RamadanBasketDonationType,
  ...RamadanIftarDonationType,
  ...AtlasBasketDonationType,
  ...NoorBasketDonationType,
}

export type AllDonationType =
  | DonationType
  | WaterForAllDonationType
  | UdhiyaDonationType
  | RamadanBasketDonationType
  | RamadanIftarDonationType
  | AtlasBasketDonationType
  | NoorBasketDonationType

export const DISABLE_ONE_TIME_FOR = [
  DonationPurpose.Udhiya,
  DonationPurpose['Ramadan Iftar'],
  DonationPurpose['Noor Ummaty'],
]
export const DISABLE_MONTHLY_FOR = [
  DonationPurpose['Gaza'],
  DonationPurpose.Zakat,
  DonationPurpose.Udhiya,
  DonationPurpose['Djibouti Center'],
  DonationPurpose['Zakat Al Fitr'],
  DonationPurpose['Ramadan Basket'],
  DonationPurpose['Ramadan Iftar'],
  DonationPurpose['Atlas Relief'],
  DonationPurpose['Noor Ummaty'],
]
export const DISABLE_AMOUNTS_FOR = [
  DonationPurpose.Zakat,
  DonationPurpose['Zakat Al Fitr'],
  DonationPurpose['Noor Ummaty'],
]

export type MonthlyAmount = 40 | 80 | 150 | 200
export const MONTHLY_AMOUNTS: MonthlyAmount[] = [40, 80, 150, 200]
export type OneTimeAmount = 80 | 150 | 250 | 300
export const ONE_TIME_AMOUNTS: OneTimeAmount[] = [80, 150, 250, 300]

export const getAmounts = (donationType: AllDonationType): number[] => {
  switch (donationType) {
    case DonationType.Monthly:
      return MONTHLY_AMOUNTS

    case DonationType['One Time']:
      return ONE_TIME_AMOUNTS

    default:
      return []
  }
}

export enum UserFieldName {
  'firstName' = 'firstName',
  'lastName' = 'firstName',
  'email' = 'email',
}
export type UserFieldNameKey = keyof typeof UserFieldName

export type UserInfo = {[key in UserFieldNameKey]: string}

export const userFields: Array<{
  name: UserFieldNameKey
  label: string
  autoComplete: string
  span?: number
  type?: string
}> = [
  {
    name: 'firstName',
    label: 'First Name',
    autoComplete: 'given-name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    autoComplete: 'family-name',
  },
  {
    name: 'email',
    label: 'Email',
    autoComplete: 'email',
    span: 2,
    type: 'email',
  },
]

export type PaymentMethod = {
  Icon: React.FC<{
    className?: string | undefined
  }>
  label: string
  methodName: 'stripe' | 'payPal'
}

export enum BuildYourOwnWellType {
  Surface = 'surface',
  Deep = 'deep',
}

export enum BuildYourOwnWellPumpType {
  Manual = 'manual',
  ManualAndWaterTank = 'manual_and_water_tank',
  ManualForOneFamily = 'manual_for_one_family',
  Electric = 'electric',
  ElectricPumpAndWaterTank = 'electric_pump_and_water_tank',
}

export type BuildYourOwnWellItem = {
  country: string
  well_type: BuildYourOwnWellType
  well_pump: BuildYourOwnWellPumpType
  well_desc: string
  unit_price: number
  disabled?: boolean
}

export type BuildYourOwnWellMetadata = {
  type: 'Build your own well'
  quantity: 1
  name_on_well: string
} & BuildYourOwnWellItem

export type UdhiyaMetadata = {
  type: 'Udhiya'
  country: string
  unit_price: number
  quantity: 1
  sacrifice: string
  name?: string
}

export type FamilyBasketMetadata = {
  type: 'Family basket'
  country?: string
  unit_price: number
  quantity: number
}

export type Metadata =
  | BuildYourOwnWellMetadata
  | UdhiyaMetadata
  | FamilyBasketMetadata

export const MINIMAL_DONATION_AMOUNT = 1
