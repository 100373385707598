/** @jsx jsx */
import {jsx} from 'theme-ui'
import Button from 'components/Button'
import {useTranslation} from 'react-i18next'
import {
  DISABLED_DONATION_PURPOSES,
  DonationPurposeSlugs,
} from './DonationBox/shared'

type Props = {
  to?: string
  className?: string
}

const DonateNowButton: React.FC<Props> = ({to, className, children}) => {
  const {t, i18n} = useTranslation()
  let path = i18n.language === 'en' ? '/donate/' : '/fr/donate/'
  if (to && !DISABLED_DONATION_PURPOSES.includes(DonationPurposeSlugs[to])) {
    path = to ? `/${to}` : path
  }
  return (
    <Button
      className={className}
      to={path}
      inverse
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children || t('Donate Now')}
    </Button>
  )
}

export default DonateNowButton
